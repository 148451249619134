.forgot-password-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: var(--background-color);
  .forgot-password-modal {
    background-color: var(--modal-background-color);
    top: 50%;
    transform: translateY(-50%);
    right: 15%;
    position: absolute;
    max-width: 700px;
    height: auto;
    width: 80%;
    display: block;
    padding: 32px;
    border-radius: 24px;

    .page-title {
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      margin-bottom: 32px;
      color: var(--headline-color);
    }

    .page-description {
      font-size: 16px;
      font-weight: 400;
      color: var(--text-color);
      margin-bottom: 42px;
      text-align: center;
    }

    .email-intput {
      margin-bottom: 41px;
    }

    .sent-link-button {
      margin-bottom: 16px;
    }
    .other-option {
      padding: 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .background-img {
    position: absolute;
    left: 0;
    top: 388px;
    width: 444px;
    height: 444px;
    background-size: contain;
    background-image: url('../../../public/assets/imgs/Not-Found.svg');
    &.sent {
      background-image: url('../../../public/assets/imgs/Inbox.svg');
      background-position: left;
    }
  }
}
