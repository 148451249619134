.result-layout-wrapper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
    position: relative;
    .nav-drawer {
        overflow: auto;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        height: 100vh;
        background-color: white;
        .needle-logo {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 21px;
            margin-bottom: 66px;
        }
        .credits-view {
            display: flex;
            flex-direction: row;
            font-size: 16px;
            font-weight: 600;
            justify-content: center;
            align-items: center;
            color: var(--dark-blue);
            padding-bottom: 48px;
            .add-credits {
                margin-left: 16px;
                padding: 6px;
                padding-left: 12px;
                padding-right: 12px;
                color: var(--dark-blue);
                border-radius: 4px;
                border-width: 1px;
                font-weight: 600;
                border-color: var(--beige-outline);
                user-select: none;
            }
        }
        .box-shadow {
            transition: box-shadow 0.3s ease-in-out;
            &:hover {
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            }
        }
        .MuiListItem-root {
            color: var(--dark-blue);
            flex-direction: column;
            .MuiListItemButton-root {
                opacity: 0.5;
                width: 100%;
                &:hover {
                    opacity: 1;
                    background-color: transparent;
                }
                &.actived {
                    color: var(--dark-blue);
                    font-weight: bold;
                    opacity: 1;
                }
                .MuiTypography-root {
                    font-family: 'Work Sans';
                    font-weight: bold;
                    font-size: 16px;
                    &.subtype {
                        font-weight: normal;
                        padding-left: 33px;
                        &.actived {
                            font-weight: bold;
                        }
                    }
                }
            }
            img {
                filter: brightness(0) saturate(100%) invert(14%) sepia(56%)
                    saturate(726%) hue-rotate(151deg) brightness(100%)
                    contrast(104%);
            }
        }
        .MuiListItemIcon-root {
            fill: var(--dark-blue);
        }
    }

    .bottom-action {
        padding: 16px 32px 16px 32px;
    }
    .refer-friend-button {
        padding: 8px 36px 8px 36px;
    }
}
