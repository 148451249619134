.text-field-default {
  .error-message {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }

  .MuiInputLabel-shrink {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.8);
  }

  legend {
    font-size: 12px;
  }

  .MuiInputBase-root {
    .MuiInputBase-input {
      border-radius: 8px;
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: rgba(0, 0, 0, 0.8);
      &::placeholder {
        color: #a3a3a3;
      }
      
    }

    
  }
}

.MuiInputLabel-root.Mui-focused {
  color: #E3960F !important
}
