@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@500;700&family=Poppins:wght@500;600&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Trispace:wght@500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

*,
html {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

:root {
    --background-color: #fefaf3;
    --modal-background-color: #ffffff;
    --primary-color: #002d43;
    --link-color: #002d43;
    --text-color: rgba(0, 0, 0, 0.8);
    --headline-color: #000000;
    --dark-background-color: #262626;
    --shopify-primary-color: #78a35c;
    --google_analytics-primary-color: #002d43;
    --dark-blue: #002d43;
    --beige-outline: #e4dfdb;
}

body {
    font-family: 'system-ui', '-apple-system', 'BlinkMacSystemFont',
        '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', '"Noto Sans"',
        'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"',
        '"Segoe UI Symbol"', '"Noto Color Emoji"', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background-color);
    overscroll-behavior: none;
    overscroll-behavior-y: none; /* for vertical scrolling */
    overscroll-behavior-x: none; /* for horizontal scrolling */
    -ms-overflow-style: none; /* for IE and Edge */
    scrollbar-gutter: stable both-edges;
}

.text-btn {
    display: inline-block;
    color: var(--primary-color) !important;
    background-color: transparent !important;
    &:hover {
        cursor: pointer;
    }
}

.custom-insize-html {
    p {
        overflow-wrap: break-word;
        max-width: calc(100%);
    }
    p a, a *, a {
        color: #0080F7 !important;
        text-decoration: underline;
        &:active {
            color: #0080F7 !important;
            text-decoration: underline;
        }
    }
    del {
        text-decoration: line-through !important;
    }
}

// a {
//     color: #e3960f;
//     text-decoration: none !important;
//     font-weight: 500;
//     &:active {
//         color: #e3960f;
//     }
// }

button:hover {
    cursor: pointer;
    opacity: 0.8;
}

button,
[role='button'] {
    cursor: auto; /* Overrides other styles due to !important */
}

.d-flex {
    display: flex;
}

.mr-4 {
    margin-right: 32px;
}

.row-button-group {
    display: flex;
    width: 100%;
    gap: 24px;
}

.column-button-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
}

.module-wrapper {
    background-color: var(--modal-background-color);
    border-radius: 16px;
    padding: 24px;

    & + .module-wrapper {
        margin-top: 32px;
    }
}

.grey-text {
    color: rgba(0, 0, 0, 0.5);
}

.page-title {
    font-family: 'system-ui', '-apple-system', 'BlinkMacSystemFont',
        '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', '"Noto Sans"',
        'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"',
        '"Segoe UI Symbol"', '"Noto Color Emoji"', sans-serif;
}

strong {
    font-weight: 600;
}
ul {
    list-style-type: initial;
    list-style-position: inside;
}

ol {
    list-style-type: decimal;
    list-style-position: inside;
}

li {
    font-size: 16px;
    display: list-item;
}

.MuiPopover-paper {
    transform: translateY(8px) !important;
    background: #ffffff;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15) !important;
    li {
        margin: 0;
    }
}
@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.answer {
    margin-top: 8px;
    font-family: 'system-ui', '-apple-system', 'BlinkMacSystemFont',
        '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', '"Noto Sans"',
        'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"',
        '"Segoe UI Symbol"', '"Noto Color Emoji"', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    margin-left: 0;
    color: rgba(0, 0, 0, 0.8);
    list-style-position: outside;
    list-style-type: initial;
    li {
        margin-left: 24px;
        &::marker {
            font-size: 15px;
        }
    }

    li + li {
        margin-top: 16px;
    }
}

.custom-scrollbar {
    position: relative;
    &::-webkit-scrollbar {
        position: absolute;
        right: -10px;
        display: none;
        transform: translateX(12px);
        width: 6px;
    }
    &:hover {
        &::-webkit-scrollbar {
            display: block;
            transform: translateX(12px);
            width: 6px;
        }
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.fade-in {
    opacity: 0;
    animation-name: fade-in;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.trispace-bold {
    font-weight: 600;
    font-family: Trispace;
    // letter-spacing: -0.4px;
}

.grey-icon {
    filter: grayscale(7) brightness(0.8);
}

#klaviyo-email-template button#close-preview {
    display: none !important;
}

.font-header-1 {
    @apply font-dm font-extrabold text-32px tracking-custom-tight leading-110;
}

.font-header-2 {
    @apply font-dm font-extrabold text-2xl tracking-custom-tight leading-110;
}

.font-header-3 {
    @apply font-dm font-extrabold text-xl tracking-custom-tight leading-110;
}

.font-header-4 {
    @apply font-dm font-extrabold text-base tracking-custom-tight leading-110;
}

.font-user-avatar {
    @apply font-dm font-extrabold text-base;
}

.font-subtitle {
    @apply font-sans font-bold text-base;
}

.font-body-text {
    @apply font-sans font-normal text-base leading-130;
}

.font-link-body-text {
    @apply font-sans font-normal text-base;
}

.font-small-text {
    @apply font-sans font-normal text-sm leading-130;
}

.font-small-text-bold {
    @apply font-sans font-bold text-sm leading-130;
}

.font-link-small-text {
    @apply font-sans font-normal text-sm cursor-pointer leading-130;
}

.font-button-text {
    @apply font-sans font-semibold text-base leading-100;
}

.font-large-body-text {
    @apply font-sans font-normal text-xl leading-130;
}

.gradient-theme-background {
    @apply bg-gradient-to-r from-pink-ai-gradient to-blue-ai-gradient;
}

.font-not-define {
    @apply font-sans font-bold text-xs leading-110;
}

.textarea-box-needle {
    @apply border border-beige-outline font-body-text py-3 px-4 w-full rounded-lg focus:outline-dark-blue mt-2 -mb-2;
}

.text-gradient {
    @apply gradient-theme-background text-transparent bg-clip-text;
}

.text-area-needle {
    @apply border border-beige-outline font-body-text py-3 px-4 w-full rounded-lg focus:outline-dark-blue;
}

.text-area-needle-error {
    @apply border border-red-theme font-body-text py-3 px-4 w-full rounded-lg focus:outline-red-theme;
}
