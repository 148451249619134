.sign-in-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: var(--background-color);
  .sign-in-modal {
    background-color: var(--modal-background-color);
    top: 100px;
    right: 32px;
    position: absolute;
    max-width: 700px;
    height: auto;
    width: 80%;
    display: block;
    padding: 32px;
    border-radius: 24px;

    .page-title {
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      margin-bottom: 32px;
      color: var(--headline-color);
    }

    .page-description {
      font-size: 16px;
      font-weight: 400;
      color: var(--text-color);
      margin-bottom: 42px;
      text-align: center;
    }

    button.login-with-google {
      padding: 0 !important;
      login-with-google {
        height: 56px;
      }
      span {
        line-height: 40px;
        font-size: 14px;
        font-weight: 500;
      }
      margin-bottom: 16px;
    }

    .divider {
      height: 40px;
      span {
        font-size: 14px;
        font-weight: 400;
        opacity: 0.5;
      }
      margin-bottom: 26px;
    }

    .email-intput {
      margin-bottom: 27px;
    }

    .password-input {
      margin-bottom: 17px;
    }

    .forget-password-wrapper {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 40px;
    }

    .login-button {
      display: inline-block;
      margin-bottom: 28px;
      padding: 12px 24px;
    }
    .other-option {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .background-img {
    position: absolute;
    left: 0;
    top: 388px;
    width: 444px;
    height: 444px;
    background-size: contain;
    background-image: url("../../../public/assets/imgs/Leadership.svg");
    background-repeat: no-repeat;
  }
}
