.gradient-background {
    background: rgb(252, 95, 255);
    background: linear-gradient(
        90deg,
        rgba(252, 95, 255, 1) 0%,
        rgba(93, 231, 255, 1) 100%
    );
}
.box-shadow {
    transition: box-shadow 0.3s ease-in-out;
    &:hover {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
}