.modal-wrapper {
  .modal-title {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
  }


  .platform-logo{
    background-color: #F5F5F5;
    width: 56px;
    height: 56px;
    padding: 12px;
    border-radius: 50%;

    img{
      width: 32px;
      height: 32px;
    }
  }

  .service-name {
    font-family: "Work Sans";
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.9);
  }

  .form-label {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .success-img {
    width: 180px;
    height: 180px;
    object-fit: contain;
  }
}
