.animated-list {
    list-style-type: none;
    padding: 0;
}

.animated-list li {
    opacity: 0;
    transform: translateY(20px);
    transition:
        opacity 0.3s ease-out,
        transform 0.3s ease-out;
}

.animated-list li.fade-in {
    opacity: 1;
    transform: translateY(0);
}

.border-custom-dashed {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23E1D8D1FF' stroke-width='4' stroke-dasharray='4%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
